<template>
  <v-row align="center" class="pa-10">
    <v-col sm="6" md="3" v-for="entry in entries">
      <v-hover v-slot="{ hover }">
        <v-card
          width="500"
          height="500"
          class="ma-auto entryCard"
          rounded="lg"
          :color="hover ? 'light-blue darken-4' : 'blue-grey lighten-5'"
          :elevation="hover ? 12 : 2"
          :class="{ onHover: hover }"
          @click="$router.push({ name: entry.routeName })"
        >
          <div class="entryTitle" v-html="entry.title" />
          <img :src="entry.img" class="entryBg" alt="" />
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      entries: [
        {
          title: '權限管理',
          img: require('@/assets/image/admin/settings.png'),
          routeName: 'Manage'
        },
        {
          title: '設備管理<br />表位同步',
          img: require('@/assets/image/admin/sync.png'),
          routeName: 'Sync'
        },
        {
          title: 'IPC',
          routeName: 'IPC'
        },
        {
          title: 'ADR',
          routeName: 'adminADR'
        },
        {
          title: '學校SSID配置',
          routeName: 'SSID'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.entryCard {
  display: flex;
  align-items: center;
  justify-content: center;
  .entryTitle {
    font-size: 50px;
    font-weight: bold;
    color: darkslategrey;
  }
  .entryBg {
    width: 100%;
    max-width: 300px;
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.onHover {
  .entryTitle {
    color: #fff;
  }
  .entryBg {
    display: none;
  }
}
</style>
